* {
  box-sizing: border-box;
}

.plan-modified {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.plan-modified p {
  font-size: 14px;
}
.Plan {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Plan .column.users {
  width: 100%;
}
.Plan .column {
  width: 26%;
  margin-bottom: 20px;
}

.column.users .droppable-col {
  flex-direction: row;
  flex-wrap: wrap;
}
.droppable-col {
  width: 100%;
  background-color: #e8eef7;
  padding: 20px 20px 5px 20px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  min-height: 56px;
}
.Plan .column.users .item {
  margin-right: 15px;
}
.item {
  margin-bottom: 15px;
  background-color: white;
  color: #637381;

  padding: 8px;
  border-radius: 8px;
}

.item.dragging {
  background-color: #2065d1;
  color: white;
}

.mainTable {
  width: 100%;
  border: 1px solid #e8eef7;
  border-collapse: collapse;
  border-spacing: 0;
}
.mainTable thead,
.mainTable tfoot {
  background-color: #e8eef7;
  border: 1px solid #e8eef7;
}
.mainTable th,
.mainTable tr td:first-child {
  text-align: left;
}
.mainTable td {
  text-align: right;
}
.mainTable input,
.mainTable select {
  width: 100%;
  height: 28px;
  border: 0px;
}
.mainTable input.tax {
  text-align: right;
}

.mainTable input,
.mainTable select {
  outline: none;
}
.mainTable input:focus,
.mainTable select:focus {
  border: 2px solid #e8eef7;
}
.mainTable td,
.mainTable th {
  width: 50%;
  padding: 5px;
  border: 1px solid #e8eef7;
}

#nettolohn th,
#nettolohn td,
#abzuge th,
#abzuge td,
#lohn th,
#lohn td {
  width: 25%;
}
/*  */

@media (min-width: 1200px) {
  .MuiContainer-root {
    max-width: 100%;
  }
}
.MuiDrawer-paper {
  background-color: #fff;
}
.leftPosition {
  float: left;
}

.filter__Button {
  height: 56px;
  margin: 20px 6px 20px 24px;
}

.filter__Button.active {
  background-color: #e8eef7;
}
.filter__Button.deleted {
  background-color: #ff484214;
}

.filter__control {
  display: inline-flex;
  width: 200px;
  margin: 15px 15px 15px 15px;
}

.filter__button {
  display: inline-flex;
  margin: 15px 15px 15px 15px;
}

@media (max-width: 767px) {
  .Plan .column {
    width: 100%;
  }
  .filter__control {
    display: inline-flex;
    width: calc(50% - 30px);
    margin: 15px 15px 0px 15px;
  }
  .filter__control > div {
    width: 100%;
  }
  .filter__Button {
    margin: 16px 6px 20px 16px;
  }
}

/* Order Modal */

.OrderModal table tr:hover,
.ProductPage table tr:hover {
  background-color: unset !important;
}

/* Profile */
.ProfileImage {
  width: 200px;
  height: 200px;
  background-color: #fff;
  object-fit: contain;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px;
}

.ProfileImageHeader {
  width: 40px;
  height: 40px;
  background-color: #fff;
  object-fit: contain;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px;
}

/* Plus Minus Buttons */
.PlusMinusButtons {
  width: 90px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 4px 6px;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.32);
}

.PlusMinusButtons button {
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.3rem;
  color: rgb(99, 115, 129);
}
.PlusMinusButtons button:hover {
  background-color: rgba(99, 115, 129, 0.08);
}

.PlusMinusButtons button:disabled {
  pointer-events: none;
  cursor: default;
  background-color: transparent;
  color: rgba(145, 158, 171, 0.8);
}

.AddToCartInput {
  width: 45px;
  height: 28px;
  margin-right: 5px;
  border-radius: 6px;
  border: 2px solid;
  text-align: center;
}
