.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: 0.25rem;
  text-align: center;
  letter-spacing: 1px;
}

.alert-danger {
  color: #842029;
  background: #f8d7da;
}
.alert-success {
  color: #0f5132;
  background: #d1e7dd;
}
